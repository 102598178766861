import React from 'react';
import {View, StyleSheet} from 'react-native';

function CellDivider() {
    return (
        <View style={styles.divider}/>
      );
}

const styles = StyleSheet.create({
    divider: {
        height: 1,
        opacity: 0.8,
        backgroundColor: "#C4C4C4",
    }
});
  
export default React.memo(CellDivider);