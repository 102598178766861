import { View,  Text, StyleSheet } from "react-native";

interface GenreBadgeProps {
    text: string;
    textColor?: string;
    backgroundColor?: string;
};

function GenreBadge(props: GenreBadgeProps) {
    const text = props.text.charAt(0).toUpperCase() + props.text.substr(1);
    const containerStyle = [styles.container, {backgroundColor: props.backgroundColor}];
    const textStyle = [styles.text, {color: props.textColor}]
    return (
        <View style={containerStyle}>
            <Text style={textStyle}>
                {text}
            </Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        alignItems: 'center',
        backgroundColor: '#301934',
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderRadius: 5,
        shadowColor: "#000000",
        shadowOpacity:  0.25,
        shadowRadius: 2,
        shadowOffset: {
            width: 0.5,
            height: 2,
        },
        elevation: 3,
    },
    text: {
        color: '#FFFFFF',
        fontFamily: 'Helvetica Neue',
        fontSize: 20,
        fontWeight: 'normal',
    },
});

export default GenreBadge;