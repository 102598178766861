import React from 'react';
import {View, Text, StyleSheet} from 'react-native';

function AppLogo() {
  const dotSize = 6;
    return (
        <View style={styles.container}>
            <Text style={styles.text}>
             {"M"}
            </Text>
            <View style={{
              width: dotSize,
              height: dotSize,
              borderRadius: dotSize / 2,
              backgroundColor: '#000000',
              marginLeft: dotSize / 2,
              marginRight: dotSize / 2,}}/>
            <Text style={styles.text}>
             {"J"}
            </Text>
        </View>
      );
}

const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      alignItems: 'center',
    },
    text: {
      color: '#000000',
      fontFamily: 'BaskerVille',
      fontSize: 35,
      fontWeight: 'normal',
    },
});
  
export default React.memo(AppLogo);