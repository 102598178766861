import {View, Image, Text, StyleSheet} from 'react-native';

import SourceBadge, { BadgeProp } from '../source_badge/source_badge';
import { NewsCardProps } from './news_card_interface';

function CompactNewsCard(props: NewsCardProps) {
    const data = props.data;
    const badgeProp: BadgeProp = {
        dotSize: 3,
        sourceFontSize: 13,
        timeFontSize: 13
    }
    return (
        <View style={styles.card}>
            <Image style={styles.image} source={{uri: data.imageUrl}}/>
            <View style={styles.content}>
                    <Text style={styles.contentTitle}>
                        {data.title}
                    </Text>
                    <View style={{alignSelf: 'flex-start'}}>
                    <Text style={styles.contentDescription} numberOfLines={3}>
                        {data.description}
                    </Text>
                    </View>
                    <SourceBadge data={data.source} badgeProp={badgeProp}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    card: {
        height: '97%',
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        shadowColor: "#000000",
        shadowOpacity:  0.25,
        shadowRadius: 2,
        shadowOffset: {
            width: 0.5,
            height: 2,
        },
        elevation: 1,
    },
    image: {
        width: "100%",
        height: '45%',
        resizeMode : 'cover',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    content: {
        display: 'flex',
        flex: 1,
        height: "55%",
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
        justifyContent: 'space-evenly',
    },
    contentTitle: {
        fontFamily: 'Helvetica Neue',
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 0.5,
    },
    contentDescription: {
        fontFamily: 'Helvetica Neue',
        fontSize: 16,
        fontWeight: 'normal',
        letterSpacing: 0.5,
    },
});

export default CompactNewsCard;
