import { ScaledSize } from "react-native";

const MAXIMUM_VIEW_WIDTH = 1000;
const MAXIMUM_VIEW_WIDTH_THRESHOLD = 1280;
const WIDTH_GROWTH_RATIO = MAXIMUM_VIEW_WIDTH / MAXIMUM_VIEW_WIDTH_THRESHOLD;

export function isMobileView(dimension: ScaledSize) {
    if (dimension.width <= 500) {
        return true;
    }
    return false;
}

export function getContentWidth(dimension: ScaledSize) {
    let contentWidth = dimension.width;
    if (dimension.width >= MAXIMUM_VIEW_WIDTH_THRESHOLD) {
        contentWidth =  MAXIMUM_VIEW_WIDTH;
    } else if (dimension.width < MAXIMUM_VIEW_WIDTH_THRESHOLD && !isMobileView(dimension)) {
        contentWidth = WIDTH_GROWTH_RATIO * dimension.width;
    }
    return contentWidth;
}
