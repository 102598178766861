import React from 'react'
import {View, Text, StyleSheet} from 'react-native';

export interface SourceBadgeData {
    sourceUrl: string;
    sourceName: string;
    publishedDate: string;
}

export interface BadgeProp {
    dotSize: number; 
    sourceFontSize: number;
    timeFontSize: number;
}

export interface SourceBadgeProps {
    data: SourceBadgeData;
    badgeProp?: BadgeProp | undefined;
}

function SourceBadge(props: SourceBadgeProps) {
    const data = props.data;
    const prop = props.badgeProp;
    let dotSize = prop && prop.dotSize ? prop.dotSize : 5;
    let fontSize = 14;
    if (prop) {
        if (prop.sourceFontSize) {
            fontSize = prop.sourceFontSize;
        }
        if (prop.timeFontSize) {
            fontSize = prop.timeFontSize;
        }
    }
    return (
    <View style={styles.badge}>
        <View style={{alignSelf: 'flex-start'}}>
            <Text style={{fontFamily: 'Helvetica Neue',fontSize: fontSize, fontWeight: 'bold', letterSpacing: 0.5,}}>
                {data.sourceName}
            </Text>
        </View>
        <View
            style={{
                width: dotSize,
                height: dotSize,
                borderRadius: dotSize / 2,
                backgroundColor: '#000000',
                marginLeft: dotSize,
                marginRight: dotSize,}}
        />
        <View style={{alignSelf: 'flex-end'}}>
            <Text style={{fontFamily: 'Helvetica Neue', fontSize: fontSize, fontWeight: 'normal', letterSpacing: 0.5,}}>
                {data.publishedDate}
            </Text>
        </View>
    </View>
    );
}

const styles = StyleSheet.create({
    badge: {
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
    }
});

export default React.memo(SourceBadge);