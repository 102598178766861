import React from 'react';
import {View, StyleSheet} from 'react-native';

import AppLogo from '../app_logo/app_logo';
import CellDivier from '../cell_divider/cell_divider';

function Header() {
    return (
        <View style={styles.header}>
            <View style={styles.appLogo}>
                <AppLogo />
            </View>
            <CellDivier />
        </View>
      );
}

const styles = StyleSheet.create({
    header: {
        backgroundColor: '#FFFFFF'
    },
    appLogo: {
        marginTop: 15,
        marginBottom: 15,
    }
});
  
export default React.memo(Header);