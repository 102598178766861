import React, { useState, useEffect } from 'react';
import {View, Image, Text, StyleSheet, Dimensions} from 'react-native';

import { getContentWidth } from '../utilities/view_utils';
import { getArticle, getTextContentFromNewsUrl } from '../api/request_handler';
import { NewsCardData } from '../components/news_card/news_card_interface';
import GenreBadge from '../components/genre_badge/genre_badge';
import CellDivider from '../components/cell_divider/cell_divider';
import SourceBadge from '../components/source_badge/source_badge';

const windowDimensions = Dimensions.get('window');

function Article(props: any) {
    const [articleText, setArticleText] = useState("");
    const [data, setData] = useState<NewsCardData | undefined>(undefined);
    const [contentWidth, setContentWidth] = useState(windowDimensions.width);

    useEffect(() => {
        const uuid = props.route?.params?.id;
        getArticle(uuid)
        .then(response => {
            setData(response)
            setArticleText(response.description);
            getTextContentFromNewsUrl(response.source.sourceUrl)
            .then(response => {
                setArticleText(response);
            });
        });
        // TODO: Prepare a catch to render error for invalid pages.
    }, []);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
          'change',
          ({window}) => {
            setContentWidth(getContentWidth(window));
          },
        );
        return () => subscription?.remove();
    });

    const contentBodyStyle = contentWidth ? [styles.contentBody, {width: contentWidth}] : [styles.contentBody];
    const contentTitleViewStyle = [styles.contentTitleView, {marginBottom: 40}];
    const sourceBadgeProp = {
        dotSize: 5,
        sourceFontSize: 15,
        timeFontSize: 15
    }

    return !data ? <View /> : (
    <View style={styles.body}>
        <View style={contentBodyStyle}>
            <View style={styles.contentHeader}>
                <View style={contentTitleViewStyle}> 
                    <Text style={styles.title}>
                        {data.title}
                    </Text>
                </View>
                <View style={styles.contentGenreView}>
                    <GenreBadge text={data.genre} />
                </View>
            </View>
            <View style={{marginTop: 20}}>
                <CellDivider/>
            </View>
            <View style={{marginVertical: 10, alignItems: 'flex-end'}}>
                <SourceBadge data={data.source} badgeProp={sourceBadgeProp} />
            </View>
            <CellDivider/>
            <Image style={styles.image} source={{uri: data.imageUrl}}/>
            <View style={{marginVertical: 50, marginHorizontal: 30}}>
                <Text style={styles.snippet}>
                    {articleText}
                </Text>
            </View>
            <CellDivider />
        </View>
    </View>
    );
}

const styles = StyleSheet.create({
    body: {
        width: '100%',
        height: '100%',
        backgroundColor: '#FFFFFF',
    },
    contentBody: {
        paddingTop: 30,
        paddingHorizontal: 30,
        alignSelf: 'center',
        height: '100%',
    },
    contentHeader: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent:'space-between',
    },
    contentTitleView: {
        alignSelf: 'flex-start',
        width: '60%',
    },
    contentGenreView: {
        alignSelf: 'flex-end',
    },
    title: {
        fontFamily: 'BaskerVille',
        fontSize: 40,
        fontWeight: '500',
    },
    image: {
        width: "75%",
        height: '30%',
        resizeMode : 'cover',
        borderRadius: 10,
        marginTop: 30,
        alignSelf:'center',
    },
    snippet: {
        fontFamily: 'Helvetica Neue',
        fontSize: 20,
        fontWeight: '500',
        letterSpacing: 0.5,
    },
});

export default React.memo(Article);