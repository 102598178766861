import React, { useState, useEffect } from 'react';
import {SafeAreaView, View, FlatList, StyleSheet} from 'react-native';

import { getNews } from '../api/request_handler';
import NewsSection, { NewsSectionData } from '../components/news_section/news_section';

const Item = ({data}: {data: NewsSectionData}) => {
  return (
    <View>
      <NewsSection data={data}/>
    </View>
  );
};

function Browse() {
    const [data, setData] = useState<NewsSectionData[]>([]);

    useEffect(() => {
      getNews().then(results => {
        setData(results)
      });
    }, []);

    return (
        <SafeAreaView style={styles.body}>
          <FlatList
            data={data}
            renderItem={({item}) => <Item data={item}/>}
            keyExtractor={item => item.topic}
          />
        </SafeAreaView>
      );
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: '#FFFFFF'
  }
});

export default React.memo(Browse);