import React from 'react';
import {View, Image, Text, StyleSheet} from 'react-native';

import SourceBadge from '../source_badge/source_badge';
import {NewsCardProps } from './news_card_interface';


function ProminentNewsCard(props: NewsCardProps) {
    const data = props.data;
    return (
        <View style={styles.card}>
            <Image style={styles.image} source={{uri: data.imageUrl}}/>
            <View style={styles.content}>
                <Text style={styles.contentTitle}>
                    {data.title}
                </Text>
                <Text style={styles.contentDescription}>
                    {data.description}
                </Text>
                <SourceBadge data={data.source}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    card: {
        width: '100%',
        height: '97%',
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        shadowColor: "#000000",
        shadowOpacity:  0.25,
        shadowRadius: 2,
        shadowOffset: {
            width: 0.5,
            height: 2,
        },
        elevation: 1,
    },
    image: {
        width: "40%",
        height: '100%',
        resizeMode : 'cover',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
    },
    content: {
        width: "60%",
        height: '100%',
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 10,
        paddingBottom: 10,
        justifyContent: 'space-evenly',
    },
    contentTitle: {
        fontFamily: 'Helvetica Neue',
        fontSize: 25,
        fontWeight: 'bold',
        letterSpacing: 0.5,
    },
    contentDescription: {
        fontFamily: 'Helvetica Neue',
        fontSize: 17,
        fontWeight: 'normal',
        letterSpacing: 0.5,
    },
});

export default React.memo(ProminentNewsCard);
