import React, { useState, useEffect } from 'react'
import {View, Text, StyleSheet, Dimensions, ScrollView} from 'react-native';
import { Link } from '@react-navigation/native';

import { NewsCardData } from '../news_card/news_card_interface';
import ProminentNewsCard from '../news_card/prominent_news_card'
import CompactNewsCard from '../news_card/compact_news_card';

export interface NewsSectionData {
    topic: string;
    cards: NewsCardData[];
}

export interface NewsSectionProps {
    data: NewsSectionData;
    sectionProps?: any;
}

const windowDimensions = Dimensions.get('window');

function NewsSection(props: NewsSectionProps) {
    const [dimensions, setDimensions] = useState(windowDimensions);
    
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
          'change',
          ({window, screen}) => {
            setDimensions(window);
          },
        );
        return () => subscription?.remove();
    });
    const isMobileView = dimensions.width < 1000;
    const data = props.data;
    const numColumns = 3
    const prominentPost = data.cards[0];
    const compactPosts = isMobileView ? data.cards : data.cards.slice(1, data.cards.length - 1);
    const prominentCardViewStyle = isMobileView ? [styles.prominentCardView, styles.hideDisplay] : [styles.prominentCardView];
    const compactCardViewStyle = isMobileView ? [styles.compactCardView] : [{}];
    const topicStyles = isMobileView ? [styles.topic, styles.center] : [styles.topic];

    const gap = 20;
    const totalGapSize = (numColumns - 1) * gap;
    let contentWidth = dimensions.width;
    if (dimensions.width >= 1280) {
        contentWidth =  1000;
    } else if (dimensions.width < 1280 && dimensions.width > 500) {
        contentWidth = 0.8 * dimensions.width;
    }
    let windowWidth = contentWidth;
    const childWidth = (windowWidth - totalGapSize) / numColumns;
    const minWidth = isMobileView ? 275 : childWidth;
    return (
        <View style={[styles.section, {width: contentWidth, alignSelf: 'center'}]}>
            <Text style={topicStyles}>
                {data.topic}
            </Text>
            <Link to={{screen: 'Article', params: {id: prominentPost.id}}} style={prominentCardViewStyle}>
                <ProminentNewsCard data={prominentPost}/>
            </Link>
            <View style={compactCardViewStyle}>
                <ScrollView showsVerticalScrollIndicator={false} scrollEnabled={false}>
                    <View style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: -(gap / 2), marginHorizontal: -(gap / 2), justifyContent: 'center'}}>
                        {compactPosts.map((post) => (
                            <Link to={{screen: 'Article', params: {id: post.id}}} key={post.id} style={{marginHorizontal: gap/2, minWidth: minWidth, minHeight: minWidth * 1.5, maxWidth: childWidth}}>
                                <CompactNewsCard data={post}/>
                            </Link>
                        ))}
                    </View>
                </ScrollView>
                <View style={{height: 1, backgroundColor: "#000000", marginTop: 30, marginBottom: 20}}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    section: {
        marginTop: 30,
    },
    topic: {
        color: '#000000',
        fontFamily: 'Helvetica Neue',
        fontSize: 20,
        fontWeight: 'bold',
    },
    center: {
        alignSelf: 'center',
    },
    prominentCardView: {
        marginTop: 30,
        marginBottom: 5,
        width: '100%',
        height: 300,
    },
    compactCardView: {
        marginTop: 30, 
        alignSelf: 'center',
    },
    hideDisplay: {
        display: 'none',
    }
});

export default NewsSection;