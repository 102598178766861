import { StyleSheet, View} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

import Browse from './src/views/browse';
import Article from './src/views/article';
import Header from './src/components/header/header';

export default function App() {
  const Stack = createNativeStackNavigator();

  const linking = {
    prefixes: ['https://mjnews.co', 'mjnews.co://'],
    config: {
      screens: {
        Browse: '',
        Article: 'article/:id',
      }
    },
  };

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator screenOptions={{ header: () => <Header/>}}>
        <Stack.Screen name="Browse" component={Browse}  />
        <Stack.Screen name="Article" component={Article} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
