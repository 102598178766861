
const SERVER_URL = process.env.NODE_ENV == 'development' ? 'http://localhost:3000' : 'https://server-dot-mj-news-408919.uw.r.appspot.com';

async function getNews() {
    const headlineUrl = `${SERVER_URL}/`;
    const headlineData = await fetch(headlineUrl).then(response => response.json());
    return headlineData.content;
}

async function getArticle(uuid: string) {
    const articleUrl = `${SERVER_URL}/article/${uuid}`;
    const articleData = await fetch(articleUrl).then(response => response.json());
    return articleData.content;
}

async function getTextContentFromNewsUrl(url: string) {
    const articleTextUrl = `${SERVER_URL}/article/article-text/${url}`;
    const articleTextData = await fetch(articleTextUrl).then(response => response.json());
    return articleTextData.content;
}

export {getNews, getArticle, getTextContentFromNewsUrl};